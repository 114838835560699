<template>
  <div>
    <h1>Categories</h1>
    <Loading v-if="loading" />
    <CategoryList v-else :categories="list" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CategoryList from '@/components/categories/CategoryList'
import Loading from '@/components/Loading'

export default {
  title: 'Categories',

  components: {
    CategoryList,
    Loading,
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapState('categories', [
      'list',
      'loading',
    ]),
  },
}
</script>

<style scoped>
</style>