<template>
  <v-card
    outlined
    class="ArticleItem"
    :to="{ name: 'article-details', params: { id: article.uuid } }"
  >
    <v-list-item class="grow">
      <v-list-item-avatar
        v-if="article.thumbnailUrl"
        size="80"
        tile
        color="grey"
      >
        <v-img
          class="elevation-6"
          alt=""
          :src="article.thumbnailUrl"
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          class="ArticleItem__title pyidaungsu"
        >
          {{ article.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <span 
            class="ArticleItem__host"
          >
            {{ article.host }} | 
          </span>
          <span 
            class="ArticleItem__time"
          >
            {{ article.publishedTime | relativeTime }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ArticleItem',

  props: [
    'article',
  ],

  filters: {
    relativeTime(datetime) {
      return moment(datetime).fromNow()
    }
  },
}
</script>

<style scoped>
.ArticleItem:not(:first-child) {
  margin-top: 8px;
}

.ArticleItem__title {
  line-height: 1.5 !important;
  white-space: normal !important;
}

.ArticleItem__category {
  font-weight: bold;
}

.ArticleItem__time {
  font-style: italic;
  font-size: 13px;
}

.ArticleItem__host {
  font-size: 13px;
}
</style>