<template>
  <div>
    <h1>Setting</h1>
    <div class="Setting">
      <v-avatar
        class="mt-15"
        :size="100"
      >
        <img
          alt="Profile Picture"
          :src="currentUser.picture"
        >
      </v-avatar>
      <h3 class="mt-3">
        {{ currentUser.name }}
      </h3>
      <v-btn
        class="mt-3"
        outlined
        color="primary"
        @click="showDeleteDataDialog"
      >
        Delete Your Data
      </v-btn>
      <v-btn
        class="mt-3"
        outlined
        color="primary"
        @click="logout"
      >
        Logout
      </v-btn>
    </div>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete your data </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteData">
            Delete Data
          </v-btn>
          <v-btn color="green darken-1" text @click="(dialog = false)">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getCurrentUser } from '@/helpers/session'
import { mapActions } from 'vuex'

export default {
  title: 'Setting',

  data() {
    return {
      dialog: false,
      message: "",
    };
  },

  computed: {
    currentUser() {
      return getCurrentUser()
    },
  },

  methods: {
    ...mapActions([
      'resetAllStates',
    ]),

    logout() {
      this.$session.destroy()
      this.$router.push({ name: 'login' })
      this.resetAllStates()
    },

    showDeleteDataDialog() {
      this.message = "You are in complete control of your personal data, and can delete it from Teatime's servers at anytime. Would you like to delete your data?.";
      this.dialog = true;
    },

    deleteData() {
      this.dialog = false;
      this.$session.destroy()
      this.$router.push({ name: 'login' })
      this.resetAllStates()
    },
  },
}
</script>

<style scoped>
.Setting {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>