<template>
  <div class="Loading">
    <v-progress-circular
      v-if="true"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.Loading {
  display: flex;
  justify-content: center;
  padding: 10px;
}
</style>