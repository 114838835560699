<template>
  <v-container 
    class="pa-0"
  >
    <v-row dense>
      <v-col
        v-for="category in categories"
        :key="category.id"
        cols="6"
        sm="4"
        md="3"
      >
        <CategoryItem :category="category" />
      </v-col>    
    </v-row>
  </v-container>
</template>

<script>
import CategoryItem from '@/components/categories/CategoryItem'

export default {
  name: 'CategoryList',

  props: [
    'categories',
  ],

  components: {
    CategoryItem,
  },
}
</script>