<template>
  <div>
    <div>
      <h2>Tea Time Privacy Policy</h2>
    </div>
    <div>
      Protecting your privacy is really important to us. Accordingly, we’re
      providing this Privacy Policy to explain our practices regarding the
      collection, use and disclosure of information that we receive when you use
      our Services. This Privacy Policy does not apply to any third-party
      websites, services or applications, even if they are accessible through
      our Services. Any information that we collect is subject to the privacy
      policy in effect at the time that Information is collected. We may,
      however, modify and revise this Privacy Policy from time to time. If we
      make any material changes to this Privacy Policy, we’ll notify you of
      those changes by posting them on the Services or other notification, and
      we’ll indicate when those changes will become effective. Also, please note
      that, unless we define a term in this Privacy Policy, all capitalized
      words used in this Privacy Policy have the same meanings as in our Terms
      of Service.
    </div>
    <div>
      <h2>How do we collect and use information?</h2>
    </div>
    <div>
      Our primary goals in collecting information are to provide and improve our
      Services, to administer your use of the Services, to send you newsletters
      and e-mails, and to enable you to enjoy and easily navigate our Services.
    </div>
    <div>
      <h3>Information Provided by You or Collected with Your Permission.</h3>
    </div>
    <div>
      If you want to use certain features of the Services, we or one of our
      third party service providers will collect certain information that can be
      used to identify you (<strong>“PII”</strong>), which may include, without
      limitation, your e-mail address, and/or other non-identifying information,
      including your use of certain social networking services
      (<strong>“SNS”</strong>) and information available on your SNS accounts.
      For example, if you let us know that you want to see what news stories the
      people you follow on Twitter are sharing, the links to these news stories
      will be sent to you via our Services. Please refer to the privacy policy
      for the SNS you use for more information on how your information will be
      shared by the SNS.
    </div>
    <div>
      <h3>Information Related to Your Use of the Services.</h3>
    </div>
    <div>
      Our servers automatically record certain information about how you use our
      Services, such as the pages or features of our Services you browse and the
      time you spend on those pages or features, the links (including ads) on
      our Services that you click on, and other statistics. We use this
      information to administer the Services. We also analyze (and may engage
      third parties to analyze) this information to improve and enhance our
      Services by expanding their features and functionality and tailoring them
      to our users’ needs and preferences.
    </div>
    <div>
      <h3>Information Sent by Your Mobile Device.</h3>
    </div>
    <div>
      We collect certain information that your mobile device sends when you use
      our Services, like user settings and the operating system.
    </div>
    <div>
      <h3>Location Information.</h3>
    </div>
    <div>
      We will collect information about your location and/or use various
      technologies to determine your location, such as sensor data from your
      device that provides information on nearby Wi-Fi access points and cell
      towers, if you enable this feature on the Services. You can enable or
      disable location services when you use our Services through your mobile
      device settings. We may use location information to improve and
      personalize our Services for you.
    </div>
    <div>
      <h2>What Information Do We Share With Third Parties?</h2>
    </div>
    <div>
      We will not share any PII that we have collected from you except as
      described below:
    </div>
    <div>
      <h3>Information Shared with Our Advertising Partners.</h3>
    </div>
    <div>
      We may engage third party advertising partners to serve advertising on and
      through the Services. These third-party advertising partners may (i)
      receive or gather information about your online browsing activity and use
      this information to serve targeted advertising to you, and (ii) use mobile
      device identifiers from your mobile device to collect information about
      you and your browsing activities, and may use any of the foregoing
      information to provide measurement services and targeted ads. For example,
      we use Facebook and its audience network to serve and better tailor
      advertising to your interests, and Facebook may collect and use your
      information as described in their
      <a href="https://www.facebook.com/about/privacy/">Data Policy</a>
      When using a mobile application you may receive tailored in-application
      advertisements. Depending on your device, you may be able to reset your
      mobile device's advertising identifier at any time by accessing the
      privacy settings on your mobile device. In addition, operating systems for
      mobile devices typically provide instructions on how to prevent the
      delivery of tailored in-application advertisements. You may review the
      support materials and/or the privacy settings for the respective operating
      systems in order to opt-out of tailored in-application advertisements.
    </div>
    <div>
      <h3>Information Shared with Our Services Providers.</h3>
    </div>
    <div>
      We may engage third party service providers to work with us to administer
      and provide the Services. These third-party services providers have access
      to your PII only for the purpose of performing services on our behalf. For
      example, we use Google Analytics, in addition to our own analytics
      technology, and other third-party analytics packages to collect, monitor
      and analyze the information we collect in order to increase TeaTime’s
      functionality and user-friendliness, to better tailor the Services to our
      users’ needs, and to understand and analyze how you are using our
      Services. We also share your information with Google as necessary to
      receive the Google Analytics service. For more information please see
      Google’s
      <a href="https://policies.google.com/privacy">privacy policy</a>
      addressing the information that is collected to provide the Google
      Analytics service and how Google uses such information. We also use
      MailChimp to collect your PII. We or MailChimp may use your e-mail address
      to send you correspondence regarding our Services, including, without
      limitation, our promotional newsletters and e-mails. For more information,
      please see MailChimp’s privacy policy
      (https://mailchimp.com/legal/privacy/) addressing the information that is
      collected to provide the promotional newsletter and e-mail delivery
      services and how MailChimp uses such information.
    </div>
    <div>
      <h3>Information Shared with Third Parties.</h3>
    </div>
    <div>
      We may share aggregated information and non-identifying information with
      third parties for industry research and analysis, demographic profiling
      and other similar purposes.
    </div>
    <div>
      <h3>Information Disclosed in Connection with Business Transactions.</h3>
    </div>
    <div>
      Information that we collect from our users, including PII, is considered
      to be a business asset. As a result, if we go out of business or enter
      bankruptcy or if we are acquired as a result of a transaction such as a
      merger, acquisition or asset sale, your PII may be disclosed or
      transferred to the third-party acquirer in connection with the
      transaction.
    </div>
    <div>
      <h3>
        Information Disclosed for Our Protection and the Protection of Others.
      </h3>
    </div>
    <div>
      We cooperate with government and law enforcement officials or private
      parties to enforce and comply with the law. We may disclose any
      information about you to government or law enforcement officials or
      private parties as we, in our sole discretion, believe necessary or
      appropriate: (i) to respond to claims, legal process (including
      subpoenas); (ii) to protect our property, rights and safety and the
      property, rights and safety of a third party or the public in general; and
      (iii) to stop any activity that we consider illegal, unethical or legally
      actionable activity.
    </div>
    <div>
      <h3>Information We Disclose with Your Consent or at Your Request.</h3>
    </div>
    <div>
      We will share your PII with third-party sites or platforms, such as social
      networking sites, if you have expressly requested that we do so.
    </div>
    <div>
      <h2>The Security of Your Information</h2>
    </div>
    <div>
      We take reasonable measures to protect the information that we collect
      from or about you (including your
      <strong>PII</strong>
      ) from unauthorized access, use or disclosure. Please be aware, however,
      that no method of transmitting information over the Internet or storing
      information is completely secure. Accordingly, we cannot guarantee the
      absolute security of any information.
    </div>
    <div>
      <h2>Links to Other Sites</h2>
    </div>
    <div>
      Our Services may contain links to other websites and services. Any
      information that you provide on or to a third-party website or service is
      provided directly to the owner of the website or service and is subject to
      that party's privacy policy. Our Privacy Policy does not apply to such
      websites or services and we’re not responsible for the content, privacy or
      security practices and policies of those websites or services. To protect
      your information, we recommend that you carefully review the privacy
      policies of other websites and services that you access.
    </div>
    <div>
      <h2>Modifying Your Information</h2>
    </div>
    <div>
      If you want us to delete your PII, please contact us at
      contact@teatimebook.com with your request. We’ll take steps to delete your
      information as soon as is practicable, but some information may remain in
      archived/backup copies for our records or as otherwise required by law.
    </div>
    <div>
      <h3>International Transfer</h3>
    </div>
    <div>
      Your PII may be transferred to, and maintained on, computers located
      outside of your state, province, country or other governmental
      jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction. If you’re located outside the United States and choose
      to provide your PII to us, we may transfer your PII to the United States
      and process it there.
    </div>
    <div>
      <h2>Our Policy Toward Children</h2>
    </div>
    <div>
      Our Services are not directed to children under 13 and we do not knowingly
      collect PII from children under 13. If we learn that we have collected PII
      of a child under 13 we will take steps to delete such information from our
      files as soon as possible.
    </div>
    <div>
      <h2>Questions?</h2>
    </div>
    <div>
      Please contact us at contact@teatimebook.com if you have any questions
      about our Privacy Policy.
    </div>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "Privacy Policy";
  },
};
</script>

<style scoped>
</style>