<template>
  <v-card
    outlined
    :to="{ name: 'bycategory', params: { id: category.id } }"
  >
    <v-img
      :src="category.image"
    ></v-img>
    <v-card-title
      class="Category__name"
    >
      {{ category.nameMm }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'CategoryItem',

  props: [
    'category'
  ],
}
</script>

<style scoped>
.Category__name {
  font-family: 'pyidaungsu';
  font-size: 16px;
  padding: 10px;
}
</style>