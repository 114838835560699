import { httpClient } from '../../api'

const defaultState = {
  trendArticles: [],
  articles: [],
  trendCount: 0,
  count: 0,
  trendPage: 1,
  page: 0,
}

export default {
  namespaced: true,

  state: {
    trendArticles: [],
    articles: [],
    trendCount: 0,
    count: 0,
    trendPage: 1,
    page: 0,
    category: undefined,
  },

  mutations: {
    setTrendArticles(state, data) {
      state.trendArticles = data
    },  

    setArticles(state, data) {
      state.articles = data
    },

    setTrendCount(state, data) {
      state.trendCount = data
    },

    setCount(state, data) {
      state.count = data
    },

    setTrendPage(state, data) {
      state.trendPage = data
    },

    setPage(state, data) {
      state.page = data
    },

    setCategory(state, data) {
      state.category = data
    },

    resetState(state) {
      Object.assign(state, defaultState)
    },
  },

  actions: {
    fetchTrendArticles({ commit }, { categories = [] }) {
      return httpClient.post('/articles/trend', { categoryList: categories })
        .then(({ data = {} }) => {
          const articles = Object.values(data)
            .reduce((ary1, ary2) => [...ary1, ...ary2])
            .map(article => ({ 
              ...article, 
              key: `trend_${article.uuid}`,
            }))
          commit('setTrendArticles', [...articles])
          commit('setTrendCount', articles.length)
        })
    },
    
    fetchArticles({ commit, state }, { page = 0, size = 10, categories = [] }) {
      return httpClient.post('/articles/articlepage', { 
        page, 
        size, 
        categoryList: categories,
      })
        .then(({ data }) => {
          const { content = [], totalElements = 0 } = data
          commit('setCount', totalElements)
          commit('setArticles', [
            ...state.articles, 
            ...content.map(article => ({
              ...article,
              key: `page_${article.uuid}`,
            }))
          ])
        })
    },
  },
}