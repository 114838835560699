<template>
  <component :is="layout" />
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import EmptyLayout from '@/layouts/EmptyLayout'

export default {
  name: 'App',

  components: {
    MainLayout,
    EmptyLayout,
  },

  data: () => ({
    layout: 'EmptyLayout',
  }),

  created() {
    this.$route.matched
      .some(record => this.layout = record.meta.layout)
  },

  watch: {
    '$route'(to) {
      to.matched.some(record =>
        this.layout = record.meta.layout)
    },
  },
};
</script>

<style>
@font-face {
  font-family: 'Pyidaungsu';
  src: url(../public/fonts/Pyidaungsu-2.5.3_Regular.ttf);
}

@font-face {
  font-family: 'Pyidaungsu-bold';
  src: url(../public/fonts/Pyidaungsu-2.5.3_Bold.ttf);
}

@font-face {
  font-family: 'Raidand';
  src: url(../public/fonts/Raidand.ttf);
}

.pyidaungsu {
  font-family: "Pyidaungsu";
}

.pyidaungsu-bold {
  font-family: "Pyidaungsu-bold";
}
</style>
