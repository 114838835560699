<template>
  <div>
    <div>
      <h2>TeaTime Terms of Service</h2>
    </div>
    <div>
      Welcome to TeaTime! You are now reading the Terms of Service (the
      “<strong>Terms</strong>”) that serve as an agreement between you and
      TeaTime, Inc. (“<strong>TeaTime</strong>,” “<strong>we</strong>,” or
      “<strong>us</strong>”) for your use of our mobile device application
      (“<strong>App</strong>”), and our news mining and delivery services,
      accessible via our App. To make these Terms easier to read, our services,
      the Site and the App are collectively called the
      “<strong>Services</strong>.”
    </div>
    <div>
      <h2>Agreement to Terms</h2>
    </div>
    <div>
      By using our Services, you agree to be bound by these Terms. If you don’t
      agree to these Terms, please do not use the Services.
    </div>
    <div>
      <h2>Changes to Terms or Services</h2>
    </div>
    <div>
      We may modify the Terms at any time, in our sole discretion. If we do so,
      we’ll let you know by posting the modified Terms on the App or through
      other communications. It’s important that you review the Terms whenever we
      modify them because if you continue to use the Services after we have
      posted modified Terms, you are indicating to us that you agree to be bound
      by the modified Terms. If you don’t agree to be bound by the modified
      Terms, then you may not use the Services anymore. Because our Services are
      evolving over time we may change or discontinue all or any part of the
      Services, at any time and without notice, at our sole discretion.
    </div>
    <div>
      <h2>Who May Use the Services</h2>
    </div>
    <div>
      You may use the Services only if you are 18 years or older and are not
      barred from using the Services under applicable law.
    </div>
    <div>
      <h2>Feedback</h2>
    </div>
    <div>
      We welcome feedback, comments and suggestions for improvements to the
      Services (“<strong>Feedback</strong>”). You can submit Feedback by
      emailing us at contact@teatimebook.com. You grant to us a non-exclusive,
      worldwide, perpetual, irrevocable, fully-paid, royalty-free, sublicensable
      and transferable license under any and all intellectual property rights
      that you own or control to use, copy, modify, create derivative works
      based upon and otherwise exploit the Feedback for any purpose.
    </div>
    <div>
      <h2>Privacy Policy</h2>
    </div>
    <div>
      Please refer to our Privacy Policy
      (https://www.teatimebook.com/privacypolicy) for information on how we
      collect, use and disclose information from our users.
    </div>
    <div>
      <h2>Content Ownership</h2>
    </div>
    <div>
      Tea Time and its licensors exclusively own all right, title and interest
      in and to the Services and all Content that is not News Content or
      Advertising Content, including all associated intellectual property
      rights. All rights to the News Content and Advertising Content are
      retained by the third-party that is the source of such Content. You
      acknowledge that the Services and Content are protected by copyright,
      trademark, and other laws of the United States and foreign countries. You
      agree not to remove, alter or obscure any copyright, trademark, service
      mark or other proprietary rights notices incorporated in or accompanying
      the Services or Content. "<strong>Content</strong>" means text, graphics,
      images, music, software, audio, video, content, works of authorship of any
      kind, and information or other materials that are posted, generated,
      distributed, disseminated, provided or otherwise made available through
      the Services. "<strong>News Content</strong>" means any and all Content
      provided via the Services that comprises news content. "<strong
        >Advertising Content</strong
      >" means advertising provided via the Services on behalf of third-parties.
      <br />
      This Agreement does not authorize you to, and you may not, copy,
      distribute, transfer, modify, publicly display, publicly perform or create
      derivative works based upon Content without our or our partners’
      permission.
    </div>
    <div>
      <h2>Rights and Terms for Apps</h2>
      <h3>Rights in App Granted by TeaTime</h3>
    </div>
    <div>
      Subject to your compliance with these Terms, TeaTime grants you a limited
      non-exclusive, non-transferable, non-sublicensable license to download and
      install a copy of the App on a mobile device or computer that you own or
      control and to run such copy of the App solely for your own personal
      non-commercial purposes. You may not copy the App, except for making a
      reasonable number of copies for backup or archival purposes. Except as
      expressly permitted in these Terms, you may not: (i) copy, modify or
      create derivative works based on the App; (ii) distribute, transfer,
      sublicense, lease, lend or rent the App to any third party; (iii) reverse
      engineer, decompile or disassemble the App; or (iv) make the functionality
      of the App available to multiple users through any means. TeaTime reserves
      all rights in and to the App not expressly granted to you under these
      Terms.
    </div>
    <div>
      <h3>Additional Terms for App Store Apps</h3>
    </div>
    <div>
      If you accessed or downloaded the App from the Apple Store, then you agree
      to use the App only: (i) on an Apple-branded product or device that runs
      iOS (Apple’s proprietary operating system software); and (ii) as permitted
      by the “Usage Rules” set forth in the Apple Store Terms of Service.
    </div>
    <div>
      If you accessed or downloaded the App from any app store or distribution
      platform (like the Apple Store, Google Play or the Amazon Appstore) (each,
      an “<strong>App Provider</strong>”), then you acknowledge and agree that:
    </div>
    <div>
      <ul>
        <li>
          These Terms are concluded between you and TeaTime, and not with App
          Provider, and that, as between TeaTime and the App Provider, TeaTime,
          is solely responsible for the App.
        </li>
        <li>
          App Provider has no obligation to furnish any maintenance and support
          services with respect to the App.
        </li>
        <li>
          In the event of any failure of the App to conform to any applicable
          warranty, you may notify App Provider and App Provider will refund the
          purchase price for the App to you (if applicable) and, to the maximum
          extent permitted by applicable law, App Provider will have no other
          warranty obligation whatsoever with respect to the App. Any other
          claims, losses, liabilities, damages, costs or expenses attributable
          to any failure of an App to conform to any warranty will be the sole
          responsibility of TeaTime.
        </li>
        <li>
          App Provider is not responsible for addressing any claims you have or
          any claims of any third party relating to the App or your possession
          and use of the App, including, but not limited to: (i) product
          liability claims; (ii) any claim that the App fails to conform to any
          applicable legal or regulatory requirement; and (iii) claims arising
          under consumer protection or similar legislation.
        </li>
        <li>
          In the event of any third-party claim that the App or your possession
          and use of the App infringes that third party’s intellectual property
          rights, TeaTime will be solely responsible for the investigation,
          defense, settlement and discharge of any such intellectual property
          infringement claim to the extent required by these Terms.
        </li>
        <li>
          App Provider and its subsidiaries are third-party beneficiaries of
          these Terms as related to your license of the App, and that, upon your
          acceptance of the terms and conditions of these Terms, App Provider
          will have the right (and will be deemed to have accepted the right) to
          enforce these Terms as related to your license of the App against you
          as a third party beneficiary thereof.
        </li>
        <li>
          You must also comply with all applicable third-party terms of service
          when using the App.
        </li>
        <li>
          You agree to comply with all U.S. and foreign export laws and
          regulations to ensure that neither the App nor any technical data
          related thereto nor any direct product thereof is exported or
          re-exported directly or indirectly in violation of, or used for any
          purposes prohibited by, such laws and regulations. By using the App
          you represent and warrant that: (i) you are not located in a country
          that is subject to a U.S. Government embargo, or that has been
          designated by the U.S. Government as a “terrorist supporting” country;
          and (ii) you are not listed on any U.S. Government list of prohibited
          or restricted parties.
        </li>
      </ul>
    </div>
    <div>
      <h3>General Prohibitions and TeaTime’s Enforcement Rights</h3>
    </div>
    <div>
      You agree not to do any of the following:
      <ul>
        <li>
          Use, display, mirror or frame the Services or any individual element
          within the Services, TeaTime’s name, any TeaTime trademark, logo or
          other proprietary information, or the layout and design of any page or
          form contained on a page, without TeaTime’s express written consent;
        </li>
        <li>
          Access, tamper with, or use non-public areas of the Services,
          TeaTime’s computer systems, or the technical delivery systems of
          TeaTime’s providers;
        </li>
        <li>
          Attempt to probe, scan or test the vulnerability of any TeaTime system
          or network or breach any security or authentication measures;
        </li>
        <li>
          Avoid, bypass, remove, deactivate, impair, descramble or otherwise
          circumvent any technological measure implemented by TeaTime or any of
          TeaTime’s providers or any other third party (including another user)
          to protect the Services or Content;
        </li>
        <li>
          Attempt to access or search the Services or Content or download
          Content from the Services through the use of any engine, software,
          tool, agent, device or mechanism (including spiders, robots, crawlers,
          data mining tools or the like) other than the software and/or search
          agents provided by TeaTime or other generally available third-party
          web browsers;
        </li>
        <li>
          Use any meta tags or other hidden text or metadata utilizing a TeaTime
          trademark, logo URL or product name without TeaTime’s express written
          consent;
        </li>
        <li>
          Use the Services or Content, or any portion thereof, for any
          commercial purpose or for the benefit of any third party or in any
          manner not permitted by these Terms;
        </li>
        <li>
          Forge any TCP/IP packet header or any part of the header information
          in any email or newsgroup posting, or in any way use the Services or
          Content to send altered, deceptive or false source-identifying
          information;
        </li>
        <li>
          Attempt to decipher, decompile, disassemble or reverse engineer any of
          the software used to provide the Services or Content;
        </li>
        <li>
          Interfere with, or attempt to interfere with, the access of any user,
          host or network, including, without limitation, sending a virus,
          overloading, flooding, spamming, or mail-bombing the Services;
        </li>
        <li>
          Collect or store any personally identifiable information from the
          Services from other users of the Services without their express
          permission;
        </li>
        <li>
          Impersonate or misrepresent your affiliation with any person or
          entity;
        </li>
        <li>Violate any applicable law or regulation; or</li>
        <li>
          Encourage or enable any other individual to do any of the foregoing.
        </li>
      </ul>
      Although we’re not obligated to monitor access to or use of the Services
      or Content, we have the right to do so for the purpose of operating the
      Services, to ensure compliance with these Terms, and to comply with
      applicable law or other legal requirements. We have the right to
      investigate violations of these Terms or conduct that affects the
      Services. We may also consult and cooperate with law enforcement
      authorities to prosecute users who violate the law.
    </div>
    <div>
      <h2>Links to Third Party Websites or Resources</h2>
    </div>
    <div>
      The Services may contain links to third-party websites, advertising, or
      resources. We provide these links only as a convenience for you. We do not
      endorse, verify or vouch for and are not responsible for any view
      expressed, or the content, products or services on or available from those
      websites, advertising, or resources or links displayed on such websites.
      You acknowledge sole responsibility for and assume all risk arising from,
      your use of any third-party websites, advertising, or resources.
    </div>
    <div>
      <h2>Termination</h2>
    </div>
    <div>
      We may terminate your access to and use of the Services, at our sole
      discretion, at any time and without notice to you. Upon any termination,
      discontinuation or cancellation of Services, the following provisions will
      survive: ownership provisions, warranty disclaimers, Indemnification,
      limitations of liability, dispute resolution, and general terms
      provisions.
    </div>
    <div>
      <h2>Warranty Disclaimers</h2>
    </div>
    <div>
      The Services and Content are provided “AS IS,” without warranty of any
      kind. Without limiting the foregoing, WE EXPLICITLY DISCLAIM ANY
      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
      ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
      DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet
      your requirements or be available on an uninterrupted, secure, or
      error-free basis. We make no warranty regarding the quality, accuracy,
      timeliness, truthfulness, completeness or reliability of any Content.
      TeaTime does not create, prepare or maintain any News Content provided by
      or available on third-party websites or resources that are accessible via
      our Services, and we assume no liability or responsibility for any such
      third-party News Content. Under no circumstances will TeaTime be liable,
      directly or indirectly, to any party for any damage or loss caused or
      alleged to be caused by or in connection with the use of or reliance on
      any such News Content. Your use of and reliance on such News Content will
      be at your own risk.
    </div>
    <div>
      <h2>Indemnity</h2>
    </div>
    <div>
      You will indemnify and hold harmless TeaTime and its officers, directors,
      employee and agents, from and against any claims, disputes, demands,
      liabilities, damages, losses, and costs and expenses, including, without
      limitation, reasonable legal and accounting fees arising out of or in any
      way connected with (i) your access to or use of the Services or Content,
      or (ii) your violation of these Terms.
    </div>
    <div>
      <h2>Limitation of Liability</h2>
    </div>
    <div>
      NEITHER TeaTime NOR ANY OTHER party involved in creating, producing, or
      delivering the services or content will be liable for any incidental,
      special, exemplary or consequential damages, INCLUDING LOST PROFITS, LOSS
      OF DATA OR GOODWILL, SERVICE INTERRUPTION, DEVICE DAMAGE OR SYSTEM FAILURE
      OR THE COST OF SUBSTITUTE SERVICES arising ouT of or in connection with
      thESE TERMS or from the use OF or inability to use the ServiceS or
      CONTENT, whether based on warranty, contract, tort (including negligence),
      PRODUCT LIABILITY or any other legal theory, and whether or not TeaTime
      has been informed of the possibility of such damage, EVEN IF A limited
      REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PUrPOSE.
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
      FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT
      APPLY TO YOU.
      <br />
      In no event will TeaTime’S total liability arising out of or in connection
      with THESE TERMS OR FROM THE USE OF OR INABILITY TO USE the ServiceS or
      content EXCEED THE AMOUNTS OF ONE HUNDRED DOLLARS ($100).
      <br />
      THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
      ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN TeaTime AND YOU.
    </div>
    <div>
      <h2>Dispute Resolution</h2>
      <h3>Governing Law</h3>
    </div>
    <div>
      These Terms and any action related thereto will be governed by the laws of
      the State of California without regard to its conflict of laws provisions.
      If you are a resident of Japan, these terms will be governed by the laws
      of Japan.
    </div>
    <div>
      <h3>Agreement to Arbitrate</h3>
    </div>
    <div>
      You and TeaTime agree that any dispute, claim or controversy arising out
      of or relating to these Terms or the breach, termination, enforcement,
      interpretation or validity thereof or the use of the Services or Content
      (collectively, "<strong>Disputes</strong>" ) will be settled by binding
      arbitration, except that each party retains the right: (i) to bring an
      individual action in small claims court and (ii) to seek injunctive or
      other equitable relief in a court of competent jurisdiction to prevent the
      actual or threatened infringement, misappropriation or violation of a
      party’s copyrights, trademarks, trade secrets, patents or other
      intellectual property rights (the action described in the foregoing clause
      (ii), an “<strong>IP Protection Action</strong>
      ”). The exclusive jurisdiction and venue of any IP Protection Action will
      be the state and federal courts located in the Northern District of
      California and each of the parties hereto waives any objection to
      jurisdiction and venue in such courts.
      <strong
        >You acknowledge and agree that you and TeaTime are each waiving the
        right to a trial by jury or to participate as a plaintiff or class
        member in any purported class action or representative
        proceeding</strong
      >
      . Further, unless both you and TeaTime otherwise agree in writing, the
      arbitrator may not consolidate more than one person's claims, and may not
      otherwise preside over any form of any class or representative proceeding.
      If this specific paragraph is held unenforceable, then the entirety of
      this “Dispute Resolution” section will be deemed void. Except as provided
      in the preceding sentence, this “Dispute Resolution” section will survive
      any termination of these Terms.
    </div>
    <div>
      <h3>Arbitration Rules</h3>
    </div>
    <div>
      The arbitration will be administered by the American Arbitration
      Association (“
      <strong>AAA</strong>
      ”) in accordance with the Commercial Arbitration Rules and the
      Supplementary Procedures for Consumer Related Disputes (the “
      <strong>AAA Rules</strong>
      ”) then in effect, except as modified by this “Dispute Resolution”
      section. (The AAA Rules are available at
      <a href="https://www.smartnews.com/terms/www.adr.org/arb_med"
        >www.adr.org/arb_med</a
      >
      or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will
      govern the interpretation and enforcement of this Section.
    </div>
    <div>
      <h3>Arbitration Process</h3>
    </div>
    <div>
      A party who desires to initiate arbitration must provide the other party
      with a written Demand for Arbitration as specified in the AAA Rules. (The
      AAA provides a form Demand for Arbitration at
      <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015820"
        >http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015820</a
      >
      and a separate form for California residents at
      <a href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822"
        >http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822</a
      >.) The arbitrator will be either a retired judge or an attorney licensed
      to practice law and will be selected by the parties from the AAA’s roster
      of arbitrators. If the parties are unable to agree upon an arbitrator
      within seven (7) days of delivery of the Demand for Arbitration, then the
      AAA will appoint the arbitrator in accordance with the AAA Rules.
    </div>
    <div>
      <h3>Arbitration Location and Procedure</h3>
    </div>
    <div>
      Unless you and TeaTime otherwise agree, the arbitration will be conducted
      in the county where you reside. If your claim does not exceed $10,000,
      then the arbitration will be conducted solely on the basis of the
      documents that you and TeaTime submit to the arbitrator, unless you
      request a hearing or the arbitrator determines that a hearing is
      necessary. If your claim exceeds $10,000, your right to a hearing will be
      determined by the AAA Rules. Subject to the AAA Rules, the arbitrator will
      have the discretion to direct a reasonable exchange of information by the
      parties, consistent with the expedited nature of the arbitration.
    </div>
    <div>
      <h3>Arbitrator’s Decision</h3>
    </div>
    <div>
      The arbitrator will render an award within the time frame specified in the
      AAA Rules. The arbitrator’s decision will include the essential findings
      and conclusions upon which the arbitrator based the award. Judgment on the
      arbitration award may be entered in any court having jurisdiction thereof.
      The arbitrator’s award of damages must be consistent with the terms of the
      “Limitation of Liability” section above as to the types and amounts of
      damages for which a party may be held liable. The arbitrator may award
      declaratory or injunctive relief only in favor of the claimant and only to
      the extent necessary to provide relief warranted by the claimant’s
      individual claim. If you prevail in arbitration you will be entitled to an
      award of attorneys’ fees and expenses, to the extent provided under
      applicable law. TeaTime will not seek, and hereby waives all rights it may
      have under applicable law to recover, attorneys’ fees and expenses if it
      prevails in arbitration.
    </div>
    <div>
      <h3>Fees</h3>
    </div>
    <div>
      Your responsibility to pay any AAA filing, administrative and arbitrator
      fees will be solely as set forth in the AAA Rules. However, if your claim
      for damages does not exceed $75,000, TeaTime will pay all such fees unless
      the arbitrator finds that either the substance of your claim or the relief
      sought in your Demand for Arbitration was frivolous or was brought for an
      improper purpose (as measured by the standards set forth in Federal Rule
      of Civil Procedure 11(b)).
    </div>
    <div>
      <h3>Changes</h3>
    </div>
    <div>
      Notwithstanding the provisions of the “Changes to Terms or Services”
      section above, if TeaTime changes this “Dispute Resolution” section after
      the date you first accepted these Terms (or accepted any subsequent
      changes to these Terms), you may reject any such change by sending us
      written notice (including by email to contact@teatimebook.com) within 30
      days of the date such change became effective, as indicated in the “Last
      Updated” date above. By rejecting any change, you are agreeing that you
      will arbitrate any Dispute between you and TeaTime in accordance with the
      provisions of this “Dispute Resolution” section as of the date you first
      accepted these Terms (or accepted any subsequent changes to these Terms).
    </div>
    <div>
      <h2>General Terms</h2>
    </div>
    <div>
      These Terms constitute the entire and exclusive understanding and
      agreement between TeaTime and you regarding the Services and Content, and
      these Terms supersede and replace any and all prior oral or written
      understandings or agreements between TeaTime and you regarding the
      Services and Content. If for any reason a court of competent jurisdiction
      finds any provision of these Terms invalid or unenforceable, that
      provision will be enforced to the maximum extent permissible and the other
      provisions of these Terms will remain in full force and effect.
      <br />
      You may not assign or transfer these Terms, by operation of law or
      otherwise, without TeaTime’s prior written consent. Any attempt by you to
      assign or transfer these Terms, without such consent, will be null.
      TeaTime may freely assign or transfer these Terms without restriction.
      Subject to the foregoing, these Terms will bind and inure to the benefit
      of the parties, their successors and permitted assigns.
      <br />
      Any notices or other communications provided by TeaTime under these Terms,
      including those regarding modifications to these Terms, will be given by
      posting to the Services.
      <br />
      TeaTime’s failure to enforce any right or provision of these Terms will
      not be considered a waiver of such right or provision. The waiver of any
      such right or provision will be effective only if in writing and signed by
      a duly authorized representative of TeaTime. Except as expressly set forth
      in these Terms, the exercise by either party of any of its remedies under
      these Terms will be without prejudice to its other remedies under these
      Terms or otherwise.
    </div>
    <div>
      <h2>Contact Information</h2>
    </div>
    <div>
      If you have any questions about these Terms or the Services, please
      contact TeaTime at:
      <br />
      contact@teatimebook.com
      <br />
      Jingumae Dai 23 Building 3F, 6-25-16 Jingumae, Shibuya-ku, Tokyo, Japan
    </div>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "Terms and Conditions";
  },
};
</script>

<style scoped>
</style>