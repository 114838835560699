import { httpClient } from '../../api'

const defaultState = {
  list: [],
  loading: false,
}

export default {
  namespaced: true,

  state: {
    list: [],
    loading: false,
  },

  mutations: {
    setCategoryList(state, data) {
      state.list = data
    },

    setLoading(state, data) {
      state.loading = data
    },
    
    resetState(state) {
      Object.assign(state, defaultState)
    },
  },

  actions: {
    fetchCategories({ commit }) {
      return httpClient.post('/category/list')
        .then(({ data }) => commit('setCategoryList', [...data]))
    },
  }
}