import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import titleMixin from './mixins/title'
import infiniteScroll from 'vue-infinite-scroll'
import VueSession from 'vue-session'

Vue.config.productionTip = false

Vue.use(infiniteScroll)
Vue.use(VueSession, { persist: true })

Vue.mixin(titleMixin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
