<template> 
  <div v-if="article">
    <v-btn
      text
      icon
      @click="goBack"
    >
      <v-icon
        dark
        left
      >
        mdi-arrow-left
      </v-icon>
    </v-btn>
    <h1
      class="pyidaungsu-bold"
    >
      {{ article.title }}
    </h1>
    <span 
      class="Article__host text--secondary"
    >
      {{ article.host }} |
    </span>
    <span 
      class="Article__time text--secondary"
    >
      {{ article.publishedTime | datetime }}
    </span>
    <span
      class="Article__category text--secondary"
    >
      | {{ article.categoryId }}
    </span>
    <div
      v-for="(content, i) in (article ? article : {}).content"
      :key="i"
      class="mt-5"
    >
      <p 
        v-if="content.key === 'content'"
        class="pyidaungsu mb-0 Article__content text-justify"
      >
        {{ content.value }}
      </p>
      <h2
        v-else-if="content.key === 'title'"
        class="pyidaungsu mt-5"
      >
        {{ content.value }}
      </h2>
      <v-img
        v-else-if="content.key === 'image'"
        :src="content.value"
      ></v-img>
      <a
        v-if="content.key === 'link'"
        class="Article__link"
        :href="retrieveLink(content.value)[1]"
      >
        {{ retrieveLink(content.value)[0] }}
      </a>
    </div>
    <div
      class="Article__visit"
    >
      <v-btn
        outlined
        small
        :href="article.url"
      >
        View Website
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { httpClient } from '@/api'
import { APP_TITLE } from '@/constants'

export default {
  data() {
    return {
      article: undefined,
    }
  },

  computed: {
    currentArticleId() {
      return this.$route.params.id
    },
  },

  mounted() {
    if (this.currentArticleId) {
      httpClient.post('/articles/detail', {
        articleId: this.currentArticleId,
      })
        .then(({ data }) => this.article = data)
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1)
    },

    retrieveLink(val) {
      return val.split('^')
    },
  },

  filters: {
    datetime(val) {
      return moment(val).format('MMMM Do YYYY, h:mm:ss a')
    },
  },

  watch: {
    article(val) {
      document.title = `${APP_TITLE} | ${val ? val.title : 'Details'}`
    },
  },
}
</script>

<style scoped>
.Article__link {
  font-size: 14px;
}

.Article__host {
  font-size: 14px;
}

.Article__time {
  font-style: italic;
  font-size: 14px;
}

.Article__category {
  font-size: 14px;  
}

.Article__content {
  line-height: 1.8;
}

.Article__visit {
  display: flex;
  justify-content: center;
  padding: 10px;
}
</style>