<template>
  <div>
    <div class="Home__title">
      <v-btn
        v-if="categoryId"
        text
        icon
        @click="goBack"
      >
        <v-icon
          dark
          left
        >
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <h1
        :class="{'pyidaungsu-bold': !!categoryId}"
      >
        {{ categoryId ? categoryDisplayName : 'Home' }}
      </h1>
    </div>
    <ArticleList :articles="articleList" />
    <Loading v-if="loading" />
    <div v-else-if="error" class="Home__error">
      <h2>{{ error }}</h2>
    </div>
    <div
      v-infinite-scroll="loadMore" 
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
    ></div>
  </div>
</template>

<script>
import ArticleList from '@/components/home/ArticleList'
import Loading from '@/components/Loading'
import { mapActions, mapState, mapMutations } from 'vuex'
import { APP_TITLE } from '@/constants'

export default {
  name: 'Home',

  title: 'Home',

  components: {
    ArticleList,
    Loading,
  },

  data() {
    return {
      limit: 10,
      loading: false,
      error: undefined,
    }
  },

  mounted() {
    if (this.storeCategory !== this.categoryId) {
      this.resetState()
    }

    this.setCategory(this.categoryId)

    if (this.articleList.length === 0) {
      this.loadArticles()
    }
  },

  methods: {
    ...mapActions('home', [
      'fetchTrendArticles',
      'fetchArticles',
    ]),

    ...mapMutations('home', [
      'setPage',
      'setTrendPage',
      'setCategory',
      'resetState',
    ]),

    loadArticles() {
      this.loading = true
      Promise.all([
        this.fetchTrendArticles({ categories: this.categoryFilter }), 
        this.fetchArticles({ 
          page: this.page, 
          size: this.limit, 
          categories: this.categoryFilter,
        })
      ])
        .then(() => {
          this.loading = false
          this.trendCount === 0 && this.handleFetchArticles()
        })
        .catch(({ response }) => {
          this.loading = false
          if (response && response.data) {
            const { status, message } = response.data
            if (
              status === 400 &&
              message === 'Invalid category.'
            ) {
              this.error = message
            }
          } else {
            this.error = 'No Songs Found'
          }
        })
    },

    handleFetchArticles() {
      this.setPage(this.page + 1)
      this.loading = true
      this.fetchArticles({ 
        page: this.page, 
        size: this.limit, 
        categories: this.categoryFilter,
      })
        .then(() => this.loading = false)
    },

    loadMore() {
      if (this.articleList.length < this.trendCount) {
        this.setTrendPage(this.trendPage + 1)
      } else if (this.articleList.length < this.trendCount + this.count) {
        this.handleFetchArticles()
      }
    },

    goBack() {
      this.$router.push({ name: 'categories' })
    },
  },

  computed: {
    ...mapState('home', [
      'trendArticles',
      'articles',
      'trendPage',
      'page',
      'trendCount',
      'count',
    ]),

    ...mapState('home', {
      storeCategory: 'category',
    }),

    ...mapState('categories', {
      categoryList: 'list',
    }),

    articleList() {
      return [
        ...this.trendArticles.slice(0, this.trendPage * this.limit),
        ...this.articles.slice(0, this.page * this.limit),
      ]
    },

    categoryId() {
      return this.$route.params.id
    },

    categoryDisplayName() {
      const category = this.categoryList.find(cat => cat.id === this.categoryId)
      document.title = `${APP_TITLE} | ${category ? category.nameMm : 'Home'}`
      return category ? category.nameMm : ''
    },

    categoryFilter() {
      return this.categoryId ? [this.categoryId] : []
    },
  },

  watch: {
    categoryId(val) {
      this.resetState()
      this.loadArticles()
      this.setCategory(val)
    },
  },
}
</script>

<style scoped>
.Home__error {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.Home__title {
  display: flex;
  align-items: center;
}
</style>
