<template>
  <div class="ArticleList">
    <ArticleItem
      v-for="article of articles"
      :key="article.key"
      :article="article"
    />
  </div>
</template>

<script>
import ArticleItem from '@/components/home/ArticleItem'

export default {
  name: 'ArticleList',

  props: [
    'articles',
  ],

  components: {
    ArticleItem,
  },
}
</script>

<style scoped>
</style>