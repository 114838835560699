import Vue from 'vue'
import Vuex from 'vuex'

import home from './modules/home'
import categories from './modules/categories'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: {
      status: undefined,
      message: '',
    },
  },

  mutations: {
    setError(state, data) {
      state.error = data
    },
  },

  actions: {
    resetAllStates({ commit }) {
      commit('home/resetState')
      commit('categories/resetState')
    },
  },

  modules: {
    home,
    categories,
  },
})
